<template>
<div>
    <div class="sign-up-form">
        <div class="back-img" v-if="userlog==null">
            <img :src="signupImg" />
            <div class="sign-up-head">
                <div>회원가입</div>
            </div>
            <v-form ref="form" class="sign-up-body">
                <div>
                    <v-text-field v-model="user.id" :rules="idRule" label="* 아이디" type="id" prepend-icon="mdi-identifier"/>
                    <v-icon @click="user.id=''">mdi-delete</v-icon>
                </div>
                <div>
                    <v-text-field v-model="user.pw" :rules="pwRule" label="* 비밀번호" type="password" prepend-icon="mdi-lock"/>
                    <v-icon @click="user.pw=''">mdi-delete</v-icon>
                </div>
                <div>
                    <v-text-field v-model="pwcheck" :rules="pwckRule" label="* 비밀번호확인" type="password" prepend-icon="mdi-lock"/>
                    <v-icon @click="pwcheck=''">mdi-delete</v-icon>
                </div>
                <div>
                    <v-text-field v-model="user.email" :rules="emailRule" label="* E-mail" type="email" prepend-icon="mdi-email"/>
                    <v-icon @click="user.email=''">mdi-delete</v-icon>
                </div>
                <div>
                    <v-text-field v-model="user.nickname" label="닉네임" type="text" prepend-icon="mdi-account"/>
                <v-icon @click="user.nickname=''">mdi-delete</v-icon>
                </div>
                <div>
                    <v-dialog
                        ref="menu"
                        v-model="menu1"
                        :return-value.sync="user.birthday"
                        presistent
                        width="290px">
                        <template v-slot:activator="{on, attrs}">
                            <v-text-field
                                v-model="user.birthday"
                                prepend-icon="mdi-calendar"
                                readonly
                                label="생년월일"
                                v-bind="attrs"
                                v-on="on"/>
                        </template>
                        <v-date-picker v-model="user.birthday" no-title show-current="2000-07-13" scrollable :max="date">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="writeBirth(user.birthday)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <v-icon @click="user.birthday=''">mdi-delete</v-icon>
                </div>
                <div class="sign-up-msg">* 이 붙은 곳은 필수 입력 항복입니다.</div>
                <div class="sign-up-footer">
                    <v-btn depressed small dark color="blue" @click="doSignUp"><v-icon>mdi-check</v-icon>가입</v-btn>
                    <!-- <v-btn depressed small dark><v-icon>mdi-close</v-icon>닫기</v-btn> -->
                </div>
            </v-form>
        </div>
        <div v-if="userlog!=null">
            현재 부정한 방법으로 접근했습니다.
        </div>
    </div>
    </div>
</template>

<script>
// import mapState from 'vuex'
import axios from 'axios'

export default{
    data(){
        return {
            
            userlog:this.$session.get("user"),
            date: (new Date()).toISOString().substr(0,10),
            signupImg:require("../assets/signupform.png"),
            user:{
                id:"",
                pw:"",
                birthday: "",
                email:"",
                nickname:"",
            },
            idRule:[
                v => !!v||'아이디를 입력해주세요',
                v => (v.length <=20&& v.length>4) || '아이디는 5~20자로 해주세요'
            ],
            pwRule:[
                // checkpw
                v => !!v||'비밀번호를 입력해주세요',
                v => (v.length <=20&& v.length>4) || '비밀번호는 5~20자로 해주세요'
            ],
            pwckRule:[
                v => !!v||'비밀번호확인을 입력해주세요',
                v => v=== this.user.pw || '패스워드가 일치하지 않습니다.'
            ],
            emailRule:[
                v => !!v||'이메일을 입력해주세요',
                v => /.+@.+\..+/.test(v) ||'이메일 형식을 확인해주세요.',
                v => v.length<40 || '죄송합니다 40자 이상의 이메일은 사용하실수 없습니다.'
            ],
            pwcheck:"",
            menu1: false,
            
        }
    },
    components:{
    },
    methods: {
        writeBirth(v){
            this.user.birthday = v;
            this.menu1 = false;
            this.$refs.menu.save(v);
        },
        doSignUp(){
            const config = {baseUrl: 'http://localhost:9999'};
            const validate = this.$refs.form.validate();
            if(validate){
                axios.post(`${config.baseUrl}/user/sign`,this.user)
                .then((res)=>{
                    console.log(res)
                    if(res.data =="succes"){
                        alert("회원가입에 성공했습니다. 로그인을 해주세요.");
                        this.$router.push('/');
                    } 
                    else {
                        console.log("fail");
                    }
                }).catch(error =>{
                    console.log(error.response);
                    if(error.response.data == "same id."){
                        alert("중복된 아이디입니다.");
                    }
                });
            }

        }
        // checkpw(v){
        // }
    },
    setup(){
        console.log(this.$session.get("user"))
    }
}
</script>

<style scoped>
.sign-up-form{
    width: 40vw;
    min-width: 320px;
    max-width: 708px;
    height: 705px;
    margin:0 auto;
}
.back-img{
    position: absolute;
    min-width: 320px;
    height: inherit;
    width: inherit;
    max-width: 768px;
}
.back-img>img{
    width: 40vw;
    min-width: 320px;
    max-width: 708px;
    height: 705px;
    margin:0 auto;
    position: absolute;
}
.sign-up-head{
    padding: 80px 0 0 0;
    font-size: 30px;
}
.sign-up-head>div{
    margin: 0 auto;
    position: relative;
    text-align: center;
}
.sign-up-body{
    position: relative;
    padding:0 10% 0 10%;
}
.sign-up-body>div{
    display: flex;
}
.sign-up-msg{
    font-size:12px;
    color:red;
}
.sign-up-footer{
    justify-content: flex-end;
}
.sign-up-footer>button{
    margin: 0 5px;
}
@media (min-width:1800px){

    .sign-up-body{
        padding:0 5% 0 10%;
        max-width: 700px;
    }
}
</style>